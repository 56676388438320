import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// axios
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)
// 动画
import 'animate.css/animate.min.css' //引入
// 引入element
import "../src/plugins/element.js"
// Element CSS样式
import "element-ui/lib/theme-chalk/index.css"
// 引入全局样式
import "./CSS/base.css"
// 引入elementUI全局样式
import "./CSS/elementUI.css"
// fade/zoom 等
import 'element-ui/lib/theme-chalk/base.css';
// 隐藏 右侧浏览器 滚动条
import VueSmoothScroll from "vue2-smooth-scroll";
Vue.use(VueSmoothScroll)


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
