<template>
  <div>
    <div class="logo">
      <router-link to="/">
        <img src="@/assets/home/logo.png" alt="" />
      </router-link>
    </div>
    <div class="navigation">
      <ul>
        <li :class="{ active1: row == 1 }" @click="goHome">首页</li>
        <li :class="{ active1: row == 2 }">
          <el-dropdown :trigger="trigger">
            <span id="span"> 解决方案 </span>
            <el-dropdown-menu
              slot="dropdown"
              style="
                color: #c4c9d2;
                width: 120px;
                text-align: center;
                background: rgba(4, 30, 80, 0.9);
                border: 0;
              "
            >
              <el-dropdown-item @click.native="goShangPiao">
                商票驿</el-dropdown-item
              >
              <el-dropdown-item @click.native="goKuaJing">
                跨境驿</el-dropdown-item
              >
              <el-dropdown-item @click.native="goShangLian">
                商链驿</el-dropdown-item
              >
              <el-dropdown-item @click.native="goPuHui">
                普惠驿</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </li>
        <li :class="{ active1: row == 3 }" @click="goScience">数字科技</li>
        <li :class="{ active1: row == 4 }" @click="goInRegardTo">关于我们</li>
      </ul>
    </div>
    <div class="show" @click="cutDrawer">
      <img src="../assets/home/三横杠.png" alt="" />
    </div>
    <!-- 弹窗 -->
    <!-- <div class="drawer" v-if="drawer">
      <div class="drawer_comtent">123123</div>
    </div> -->
    <el-drawer
      class="drawer"
      title=""
      :modal="false"
      :visible.sync="drawer"
      direction="ltr"
      :before-close="handleClose"
      size="70%"
    >
      <ul>
        <li :class="{ active1: row == 1 }" @click="goHome">
          <span>首页</span>
        </li>
        <li :class="{ active1: row == 2 }">
          <span>解决方案</span>
        </li>
        <li @click="goKuaJing" :class="{ active1: row1 == 1 }">
          <span style="margin-left: 15px">跨境驿</span>
        </li>
        <li @click="goShangPiao" :class="{ active1: row1 == 2 }">
          <span style="margin-left: 15px">商票驿</span>
        </li>
        <li @click="goShangLian" :class="{ active1: row1 == 3 }">
          <span style="margin-left: 15px">商链驿</span>
        </li>
        <li @click="goPuHui" :class="{ active1: row1 == 4 }">
          <span style="margin-left: 15px">普惠驿</span>
        </li>
        <li :class="{ active1: row == 3 }" @click="goScience">
          <span>数字科技</span>
        </li>
        <li :class="{ active1: row == 4 }" @click="goInRegardTo">
          <span>关于我们</span>
        </li>
        <li :class="{ active1: row == 5 }" @click="goConsult">
          <span>留言咨询</span>
        </li>
      </ul>
    </el-drawer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      drawer: false,
      row: "1", //上侧导航栏状态变量
      row1: 0,
      trigger: "click",
    };
  },
  mounted() {
    if (this.$route.path == "/") {
      this.row = 1;
    } else if (
      this.$route.path == "/kuajing" ||
      this.$route.path == "/shangpiao" ||
      this.$route.path == "/shanglian" ||
      this.$route.path == "/puhui"
    ) {
      this.row = 2;
    } else if (this.$route.path == "/science") {
      this.row = 3;
    } else if (this.$route.path == "/inRegardTo") {
      this.row = 4;
    } else if (this.$route.path == "/consult") {
      this.row = 5;
    }
    if (this.$route.path == "/kuajing") {
      this.row1 = 1;
    } else if (this.$route.path == "/shangpiao") {
      this.row1 = 2;
    } else if (this.$route.path == "/shanglian") {
      this.row1 = 3;
    } else if (this.$route.path == "/puhui") {
      this.row1 = 4;
    }
    // window.screenWidth = document.body.clientWidth;
    // if (window.screenWidth < 600) {
    //   this.trigger = "click";
    // }
  },
  methods: {
    // 切换
    cutDrawer() {
      if (this.drawer) {
        this.drawer = false;
      } else {
        this.drawer = true;
      }
    },
    handleClose(done) {
      done();
    },
    goHome() {
      if (this.$route.path == "/") return;
      this.$router.push("/");
    },
    goKuaJing() {
      if (this.$route.path == "/kuajing") return;

      this.$router.push("/kuajing");
    },
    goShangPiao() {
      if (this.$route.path == "/shangpiao") return;
      this.$router.push("/shangpiao");
    },
    goShangLian() {
      if (this.$route.path == "/shanglian") return;
      this.$router.push("/shanglian");
    },
    goPuHui() {
      if (this.$route.path == "/puhui") return;
      this.$router.push("/puhui");
    },
    // 跳转数字科技
    goScience() {
      if (this.$route.path == "/science") return;
      this.$router.push("/science");
    },
    // 跳转至关于我们
    goInRegardTo() {
      if (this.$route.path == "/inRegardTo") return;
      this.$router.push("/inRegardTo");
    },
    // 跳转留言咨询
    goConsult() {
      if (this.$route.path == "/consult") return;
      this.$router.push("/consult");
    },
  },
};
</script>

<style lang="scss" scoped>
.logo {
  position: absolute;
  // @media only screen and (min-width: 0) and (max-width: 899px) {
  //   display: none;
  // }
  @media only screen and (min-width: 0) and (max-width: 600px) {
    display: flex;
    align-items: center;
  }
  @media only screen and (min-width: 0px) and (max-width: 1199px) {
    left: 20px;
  }
  left: 17%;
  display: flex;
  align-items: center;
  width: 94px;
  height: 80px;
  @media only screen and (min-width: 0) and (max-width: 899px) {
    width: 65px;
    height: 60px;
  }
  img {
    width: 115px;
    @media only screen and (min-width: 0) and (max-width: 899px) {
      width: 85px;
    }
  }
}
// 导航栏
.navigation {
  position: absolute;
  @media only screen and (min-width: 0) and (max-width: 949px) {
    display: none;
  }
  @media only screen and (min-width: 950px) and (max-width: 2580px) {
    right: 16%;
  }
  @media only screen and (min-width: 0) and (max-width: 600px) {
    top: 0px;
  }
  top: 10px;
  width: 500px;
  height: 60px;
  ul {
    height: 60px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    // @media only screen and (min-width: 0) and (max-width: 800px) {
    //   justify-content: center;
    // }
    li {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 85px;
      height: 80px;
      font-size: 18px;
      @media only screen and (min-width: 0) and (max-width: 899px) {
        font-size: 15px;
      }
      @media only screen and (min-width: 0) and (max-width: 600px) {
        height: 60px;
      }
      font-family: Source Han Sans SC;
      font-weight: 500;
      color: #919eb2;
      cursor: pointer;
      &.active1 {
        color: #fff;
        border-bottom: 2px solid #fff;
        #span {
          color: #fff;
        }
      }
    }
  }
  #span {
    font-weight: 500;
    color: #919eb2;
    font-size: 18px;
    @media only screen and (min-width: 0) and (max-width: 899px) {
      font-size: 15px;
    }
  }
}
.show {
  height: 100%;
  position: absolute;
  right: 30px;
  display: flex;
  align-items: center;
  @media only screen and (min-width: 950px) and (max-width: 2580px) {
    display: none;
  }
  img {
    width: 26px;
  }
}
.drawer {
  ul {
    box-sizing: border-box;
    padding: 5px 15px;
    li {
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 50px;
      text-transform: uppercase;
      color: rgba(2552, 255, 255, 0.6);
      max-width: calc(100% - 36px);
      width: 100%;
      height: 50px;
      border-bottom: 1px solid #919eb2;
      &.active1 {
        color: #fff;
      }
      &.active2 {
        color: #fff;
      }
      span {
        width: 100%;
        // border-bottom: 1px solid #919eb2;
      }
    }
  }
  // position: absolute;
  // top: 80px;
  // width: 50%;
  // height: 100vh;
  // animation: move-right 1.5s ease-in-out;
  // background-color: rgba(1, 32, 64);
  // .drawer_comtent {
  //   box-sizing: border-box;
  //   padding: 25px 50px;
  //   width: 100%;
  //   height: 100%;
  // }
}
::v-deep .el-drawer {
  background-color: #061552;
}
::v-deep .el-drawer__header {
  margin-bottom: 0px;
}
::v-deep .el-drawer__body {
  box-sizing: border-box;
  // padding: 5px 25px;
  background-color: #061552;
}
// 动画
@keyframes move-right {
  0% {
    width: 0;
  }
  100% {
    width: 50%;
  }
}

//  @keyframes drawer{
// 0%{
//  transform: translate(0,0);
// }
// 100%{
// transform: translate(500px,0);
// }
::v-deep .el-dropdown-menu__item {
  font-size: 16px;
  color: #c4c9d2;
}
::v-deep .el-dropdown-menu__item:not(.is-disabled):hover {
  color: #fff;
  background: rgba(4, 30, 80, 0.9);
}
::v-deep .el-checkbox-group {
  margin-top: 5px;
}
</style>
