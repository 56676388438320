var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('el-container',{staticClass:"home-container"},[_c('el-header',{class:{ roll: _vm.roll }},[_c('Header')],1),_c('el-main',[_c('div',{staticClass:"banner-wrap"},[_c('div',{staticClass:"page-container hidden-xs",staticStyle:{"height":"100%"}},[_c('div',{class:{
              strategicMaps_title: true,
              animated: true,
              fadeInUp: true,
            }},[_c('h1',[_vm._v("供应链金融科技解决方案专家")]),_c('h5',[_vm._v("数字金融赋能产业发展")])]),_c('img',{staticClass:"show",attrs:{"src":require("../assets/home/005.png"),"alt":""}}),_c('video',{staticClass:"bg-video",attrs:{"width":"100%","id":"video1","autoplay":"","loop":"","muted":"","x-webkit-airplay":"true","airplay":"allow","webkit-playsinline":"true","playsinline":"true","src":require("@/assets/vedio/vedio.mp4")},domProps:{"muted":true}})])]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"f2",class:{
            strategicMaps_title: true,
            animated: true,
            fadeInUp: true,
          }},[_c('div',{staticClass:"f2_title"},[_vm._v("科技赋能 提质增效")]),_c('div',{staticClass:"f2_list"},[_c('div',{staticClass:"f2_content"},[_c('ul',[_c('li',{staticClass:"li1"},[_c('img',{attrs:{"src":require("../assets/home/组73.png"),"alt":""}}),_c('span',[_vm._v("人工智能")])]),_c('li',[_c('img',{attrs:{"src":require("../assets/home/组75.png"),"alt":""}}),_c('span',{staticStyle:{"left":"55px"}},[_vm._v("区块链")])]),_c('li',{staticClass:"li2"},[_c('img',{attrs:{"src":require("../assets/home/组80.png"),"alt":""}}),_c('span',{staticStyle:{"left":"55px"}},[_vm._v("云计算")])]),_c('li',{staticClass:"li3"},[_c('img',{attrs:{"src":require("../assets/home/组81.png"),"alt":""}}),_c('span',{staticStyle:{"left":"55px"}},[_vm._v("大数据")])]),_c('li',{staticClass:"li4"},[_c('img',{attrs:{"src":require("../assets/home/组83.png"),"alt":""}}),_c('span',{staticStyle:{"left":"55px"}},[_vm._v("物联网")])])])])])]),_c('div',{staticClass:"f3"},[_c('div',{staticClass:"f3_title"},[_vm._v("解决方案")]),_c('div',{staticClass:"f3_ul"},[_c('ul',[_c('li',{class:{ active2: _vm.cut == 1 },on:{"click":function($event){_vm.cut = 1;
                  _vm.testShow1 = true;
                  _vm.testShow4 = false;
                  _vm.testShow2 = false;
                  _vm.testShow3 = false;}}},[_vm._v(" 商票驿 ")]),_c('li',{class:{ active2: _vm.cut == 2 },on:{"click":function($event){_vm.cut = 2;
                  _vm.testShow2 = true;
                  _vm.testShow1 = false;
                  _vm.testShow4 = false;
                  _vm.testShow3 = false;}}},[_vm._v(" 跨境驿 ")]),_c('li',{class:{ active2: _vm.cut == 3 },on:{"click":function($event){_vm.cut = 3;
                  _vm.testShow3 = true;
                  _vm.testShow1 = false;
                  _vm.testShow2 = false;
                  _vm.testShow4 = false;}}},[_vm._v(" 商链驿 ")]),_c('li',{class:{ active2: _vm.cut == 4 },on:{"click":function($event){_vm.cut = 4;
                  _vm.testShow4 = true;
                  _vm.testShow1 = false;
                  _vm.testShow2 = false;
                  _vm.testShow3 = false;}}},[_vm._v(" 普惠驿 ")])])]),_c('div',{staticClass:"f3_content"},[(_vm.cut == 1)?[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('div',{staticClass:"f3_content_left",class:{
                    strategicMaps_title: true,
                    animated: true,
                    fadeInUp: _vm.testShow1,
                  }},[_c('img',{attrs:{"src":require("../assets/home/组40.png"),"alt":""}})]),_c('div',{staticClass:"f3_content_right",class:{
                    strategicMaps_title: true,
                    animated: true,
                    fadeInUp: _vm.testShow1,
                  }},[_c('h3',[_vm._v("商票驿")]),_c('h5',[_vm._v(" “互联网+票据融通+智慧运营” 科技平台，立足供应链内在价值链条，构建票据信息撮合和融通的智慧生态 ")]),_c('div',{staticClass:"f3_content_right_btn",on:{"click":_vm.goShangPiao}},[_vm._v(" 了解详情 ")])])])]:_vm._e(),(_vm.cut == 2)?[_c('div',{staticClass:"f3_content_left",class:{
                  strategicMaps_title: true,
                  animated: true,
                  fadeInUp: _vm.testShow2,
                }},[_c('img',{attrs:{"src":require("../assets/home/组41.png"),"alt":""}})]),_c('div',{staticClass:"f3_content_right",class:{
                  strategicMaps_title: true,
                  animated: true,
                  fadeInUp: _vm.testShow2,
                }},[_c('h3',[_vm._v("跨境驿")]),_c('h5',[_vm._v(" 跨境贸易场景企业服务综合平台，打造跨境贸易流通的综合服务生态 ")]),_c('div',{staticClass:"f3_content_right_btn",on:{"click":_vm.goKuaJing}},[_vm._v(" 了解详情 ")])])]:_vm._e(),(_vm.cut == 3)?[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('div',{staticClass:"f3_content_left",class:{
                    strategicMaps_title: true,
                    animated: true,
                    fadeInUp: _vm.testShow3,
                  }},[_c('img',{attrs:{"src":require("../assets/home/组42.png"),"alt":""}})]),_c('div',{staticClass:"f3_content_right",class:{
                    strategicMaps_title: true,
                    animated: true,
                    fadeInUp: _vm.testShow3,
                  }},[_c('h3',[_vm._v("商链驿")]),_c('h5',[_vm._v(" 供应链核心企业数字化转型服务平台，以供应链金融科技平台赋能核心企业资本、资金运营，提高供应链体系运行效率 ")]),_c('div',{staticClass:"f3_content_right_btn",on:{"click":_vm.goShangLian}},[_vm._v(" 了解详情 ")])])])]:_vm._e(),(_vm.cut == 4)?[_c('div',{staticClass:"f3_content_left",class:{
                  strategicMaps_title: true,
                  animated: true,
                  fadeInUp: _vm.testShow4,
                }},[_c('img',{attrs:{"src":require("../assets/home/组43.png"),"alt":""}})]),_c('div',{staticClass:"f3_content_right",class:{
                  strategicMaps_title: true,
                  animated: true,
                  fadeInUp: _vm.testShow4,
                }},[_c('h3',[_vm._v("普惠驿")]),_c('h5',[_vm._v(" 产业场景普惠金融创新与运营平台，打造专业的产业场景平台金融服务赋能解决方案 ")]),_c('div',{staticClass:"f3_content_right_btn",on:{"click":_vm.goPuHui}},[_vm._v(" 了解详情 ")])])]:_vm._e()],2)]),_c('div',{staticClass:"f4"},[_c('div',{staticClass:"f4_title"},[_vm._v("业务模式")]),_c('div',{staticClass:"f4_comtent"},[_c('img',{attrs:{"src":require("../assets/home/组38.png"),"alt":""}})])]),_c('div',{staticClass:"f5"},[_c('div',{staticClass:"f5_comtent"},[_c('h3',[_vm._v("数智赋能 共创未来")]),_c('div',{staticClass:"btn",on:{"click":_vm.goConsult}},[_vm._v("留言咨询")])])])]),_c('Footer')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }