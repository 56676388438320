<template>
  <div id="app">
    <!-- <transition name="fade" mode="out-in"> -->
    <router-view
      :class="{
        strategicMaps_title: true,
        animated: true,
        fadeIn: true,
      }"
    ></router-view>
    <!-- </transition> -->
  </div>
</template>

<script>
export default {
  name: "app",
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
// 切换过渡
.fade-enter {
  opacity: 0;
}

.fade-leave {
  opacity: 1;
}

.fade-enter-active {
  transition: opacity 0.2s;
}

.fade-leave-active {
  opacity: 0;
  transition: opacity 0.2s;
}
</style>
